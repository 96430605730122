import React, { useState, useEffect, useRef } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../mainComponents/Layout"
import Footer from "../mainComponents/Footer"
import FooterMail from "../components/FooterMail"
import SEO from "../mainComponents/seo"
import UkraineMap from "../assets/UkraineMap.png"
import UkraineMapMobile from "../assets/UkraineMapMobile.png"
import HiringMeta from "../assets/HiringMeta.png"

import IconArrowRight from "../assets/icon-arrow-right.svg"

import API from "../API"

import "../style/career.scss"
import Wrapper from "../mainComponents/Wrapper"

export const query = graphql`
  query CareerQuery {
    allCareerDataJson {
      edges {
        node {
          id
          title
          place
        }
      }
    }
  }
`

const CareerPage = ({ data }) => {
  const [modal, setModal] = useState(false)

  const InitialData = {
    title: "",
    name: "",
    mail: "",
    phone: "",
    text: "",
  }

  const [modalData, setModalData] = useState(InitialData)
  const [modalFile, setModalFile] = useState("")

  const careerData = data.allCareerDataJson.edges

  const ref = useRef()

  const handleClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setModal(false)
      setModalData(InitialData)
      setModalFile("")
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  const changeModal = () => {
    setModal(!modal)
    setModalData(InitialData)
    setModalFile("")
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setModalData(prevState => ({ ...prevState, [name]: value }))
  }

  const handleFileChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      setModalFile(e.target.files[0])
    }
  }

  const sendForm = e => {
    e.preventDefault()
    if (!modalData.name || !modalData.mail || !modalData.text) return

    const formData = new FormData()
    formData.append("type", "vacancy")
    formData.append("field_of_work", modalData.title)
    formData.append("name", modalData.name)
    formData.append("email", modalData.mail)
    formData.append("phone", modalData.phone)
    formData.append("description", modalData.text)
    formData.append("file", modalFile)

    API.post("", formData)
      .then(res => {

        if (res.status === 200) {
          setModal(false)
          setModalData(InitialData)
          setModalFile("")
        }
      })
      .catch(() => {

      })
  }

  return (
    <Wrapper>
      <Layout>
        <SEO
          title="Career"
          description={"Join Movadex team to revolutionise software development!"}
          img={HiringMeta}
        />

        {modal ? (
          <div className="modal">
            <div ref={ref} className="modal__wrapper">
              <svg
                className="modal__close"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={changeModal}
              >
                <path
                  d="M25.0442 2.54463L23.4527 0.953125L12.9977 11.4081L2.54267 0.953125L0.951172 2.54463L11.4062 12.9996L0.951172 23.4546L2.54267 25.0461L12.9977 14.5911L23.4527 25.0461L25.0442 23.4546L14.5892 12.9996L25.0442 2.54463Z"
                  fill="#1958E3"
                />
              </svg>

              <h4 className="modal__title">{modalData.title}</h4>

              <form className="modal-form" onSubmit={sendForm}>
                <input
                  className="modal-form__name"
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={modalData.name}
                  onChange={handleInputChange}
                  required
                />

                <input
                  className="modal-form__mail"
                  type="email"
                  name="mail"
                  placeholder="E-mail"
                  value={modalData.mail}
                  onChange={handleInputChange}
                  required
                />

                <input
                  className="modal-form__phone"
                  type="tel"
                  name="phone"
                  placeholder="Phone (optional)"
                  value={modalData.phone}
                  onChange={handleInputChange}
                />

                <textarea
                  className="modal-form__text"
                  name="text"
                  placeholder="Tell us about yourself"
                  value={modalData.text}
                  onChange={handleInputChange}
                  required
                ></textarea>

                <button className="modal-form__submit">Send</button>

                <label className="modal-form-file">
                  <input className="modal-form-file__input" type="file" onChange={handleFileChange} />
                  <span className="modal-form-file__span">
                    <svg
                      className="modal-form-file__icon"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="24"
                        cy="24"
                        r="24"
                        fill="#1958E3"
                        fillOpacity="0.1"
                      />
                      <path
                        d="M28.2294 25.3471L24.0014 21.1191L19.7734 25.3471L20.8344 26.4081L23.2514 23.9911V35.2501H24.7514V23.9911L27.1684 26.4081L28.2294 25.3471Z"
                        fill="#1958E3"
                      />
                      <path
                        d="M30.751 19.568V19.5C30.751 15.778 27.723 12.75 24.001 12.75C20.279 12.75 17.251 15.778 17.251 19.5V19.617C16.1397 19.8271 15.1152 20.3608 14.306 21.151L14.307 21.15C13.8143 21.6271 13.4225 22.1983 13.1551 22.8298C12.8876 23.4613 12.7498 24.1402 12.75 24.826C12.75 26.285 13.349 27.674 14.438 28.739C15.502 29.779 16.938 30.375 18.378 30.375H21.375V28.875H18.378C16.179 28.875 14.25 26.983 14.25 24.826C14.25 22.845 15.921 21.174 18.053 21.022L18.75 20.972V19.5C18.75 18.1076 19.3031 16.7723 20.2877 15.7877C21.2723 14.8031 22.6076 14.25 24 14.25C25.3924 14.25 26.7277 14.8031 27.7123 15.7877C28.6969 16.7723 29.25 18.1076 29.25 19.5V21.002L29.991 21.011C32.169 21.039 33.75 22.643 33.75 24.826C33.75 27.097 32.079 28.875 29.945 28.875H26.625V30.375H29.962C30.673 30.3757 31.3764 30.2287 32.0276 29.9433C32.6788 29.6579 33.2636 29.2403 33.745 28.717L33.748 28.713C34.716 27.672 35.249 26.292 35.249 24.826C35.249 22.087 33.361 19.94 30.749 19.568H30.751Z"
                        fill="#1958E3"
                      />
                    </svg>
                  </span>
                  <span className="modal-form-file__text">{modalFile ? modalFile.name : "Attach CV"}</span>
                </label>
              </form>
            </div>
          </div>
        ) : null}

        <section className="career">

          <section className="hiring-header">
            <span className="title">We are hiring</span>
            <span>If you are a displaced IT-industry worker, please reach out to</span>
            <a href="mailto:kristina@movadex.com">kristina@movadex.com</a>
            <span>for job opportunities.</span>

            <img className="map" src={UkraineMap} alt="" />
            <img className="map-mobile" src={UkraineMapMobile} alt="" />
          </section>
          <div className="container">
            <h2 className="career__title">
              Opportunities for like-minded people
            </h2>

            <h4 className="career__subtitle">Join our venturing family</h4>

            <div className="career-info">
              <p className="career-info__text">
                Movadex has revolutionized not only software outsourcing model, but also internal management. We promote
                a collaborative environment where colleagues are advisors who provide their assistance to each other. We
                believe that growing as a team is key to success. Hence, investing in the education of our employees
                remains our top priority.
              </p>

              <p className="career-info__text">
                You will be immersed into our idea-generation processes, have the same impact as everyone else in the
                team, have freedom to express your thoughts, experiment, and handpick the projects you are most
                interested in. Let’s work on turning ideas into reality. Apply today to join us in enteprising with
                ventures of tomorrow.
              </p>
            </div>

            <div className="career-job">
              {careerData.map(({ node }) => (
                <div
                  className="career-job__item"
                  key={node.id}
                  onClick={() => {
                    changeModal()
                    setModalData(prevState => ({
                      ...prevState,
                      title: node.title,
                    }))
                  }}
                >
                  <div className="career-job__block">
                    <h4 className="career-job__title">{node.title}</h4>
                    <p className="career-job__address">{node.place}</p>
                  </div>

                  <div className="career-job__block">
                    <p className="career-job__apply">Apply for a job</p>
                    <img
                      className="career-job__icon"
                      src={IconArrowRight}
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="whywork">
          <div className="container">
            <h2 className="whywork__title">Why work in Movadex</h2>

            <div className="whywork__wrapper">
              <div className="whywork-item">
                <h2 className="whywork-item__title">01</h2>

                <div className="whywork-item__block">
                  <p className="whywork-item__text">
                    By choosing us, you will be able to work with an internationally-awarded company that follows high
                    industry standards for pay, work hours, and work ethic. Working with Movadex will ensure reliability
                    to meet your professional goals.
                  </p>

                  <h5 className="whywork-item__slogan">
                    Enjoy international work standards
                  </h5>
                </div>
              </div>

              <div className="whywork-item">
                <h2 className="whywork-item__title">02</h2>

                <div className="whywork-item__block">
                  <p className="whywork-item__text">
                    Movadex is not about “I” but rather about “We.” Being part of our Movadex team, you will have an
                    opportunity to make a true impact by sharing your creative ideas and starting your own venture
                    together with your colleagues.
                  </p>

                  <h5 className="whywork-item__slogan">
                    Launch your own ventures
                  </h5>
                </div>
              </div>

              <div className="whywork-item">
                <h2 className="whywork-item__title">03</h2>

                <div className="whywork-item__block">
                  <p className="whywork-item__text">
                    Joining Movadex you will become a part of not only a great company with vast resources but also a
                    big family. Our cooperative approach lets us achieve even the most challenging goals in the most
                    favorable and creative ways.
                  </p>

                  <h5 className="whywork-item__slogan">
                    Create life-long relationships
                  </h5>
                </div>
              </div>

              <div className="whywork-item">
                <h2 className="whywork-item__title">04</h2>

                <div className="whywork-item__block">
                  <p className="whywork-item__text">
                    If you are not fond of a typical hierarchical structure at workspace, you are just the right fit for
                    our company. At Movadex, we have a horizontal organizational structure where accountability is
                    equally shared.
                  </p>

                  <h5 className="whywork-item__slogan">
                    Avoid corporate ladder
                  </h5>
                </div>
              </div>

              <div className="whywork-item">
                <h2 className="whywork-item__title">05</h2>

                <div className="whywork-item__block">
                  <p className="whywork-item__text">
                    A huge part of our mission is to invest in education. At Movadex, you will be able to constantly
                    grow, accessing a vast variety of opportunities to expand your skillset together with your
                    colleagues.
                  </p>

                  <h5 className="whywork-item__slogan">
                    Grow and learn daily
                  </h5>
                </div>
              </div>

              <div className="whywork-item">
                <h2 className="whywork-item__title">06</h2>

                <div className="whywork-item__block">
                  <p className="whywork-item__text">
                    The best thing about being part of Movadex is that we always combine productive work with simply
                    having fun. We believe it is crucial to enjoy the process to enhance creativity and problem-solving.
                  </p>

                  <h5 className="whywork-item__slogan">
                    Simply have fun
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>

      <Footer>
        <FooterMail pageName="career" />
      </Footer>
    </Wrapper>
  )
}

export default CareerPage
